import React from "react";
import NavBar from "./NavBar";
import {Badge, Button, Col, Container, ListGroup, Row} from "react-bootstrap";
import Link from "next/link";
import {openModal} from "../modals/modalActions";
import {useDispatch} from "react-redux";
import {useTrends} from "../../utilities/utils";
import {useRouter} from "next/router";
import Footer from "../../components/Footer";
import SearchNavBar from "./SearchNavBar";

import 'semantic-ui-css/components/icon.min.css'
import {Header, Icon, Segment} from "semantic-ui-react";
import {useUser} from "../../context/userContext";
import {signOut} from "firebase/auth";
import {auth} from "../../config/firebase";


function Layout({mainContent, sidebarContent, searchNav, initialSearchTerm}) {

    const router = useRouter();
    const dispatch = useDispatch()
    const {userId, notificationCount} = useUser()

    const {trends} = useTrends()

    const renderTrends = () => {
        return (
            <div className={'d-none d-lg-block p-3'}>
                <Segment
                    textAlign='center'
                    attached='top'
                    inverted
                    // color='teal'
                    className={'border-0'}
                    style={{backgroundColor: '#008B4B'}}
                >
                    <Header>Trends</Header>
                </Segment>
                <ListGroup>
                    {trends?.map(trend => (
                        <ListGroup.Item className={'p-2'}>
                            <Link href={`/trend/${encodeURIComponent(trend)}`}>
                                <div className={
                                    'd-flex flex-row'
                                }>
                                    <h6 className={'ml-3 align-self-center text-left'}>{trend}</h6>
                                </div>
                            </Link>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </div>
        )
    }

    return (
        <Container fluid={true} className={'px-0'}>
            {!searchNav && <NavBar userId={userId} notificationCount={notificationCount}/>}
            {searchNav && <SearchNavBar initialSearchTerm={initialSearchTerm}/>}
            <Row noGutters className={'min-vh-100'}>
                <Col xs={0} sm={0} md={2} lg={1} xl={2} className={'bg-light-primary d-none d-md-block'}>
                    <div className="sticky-top" style={{top: '65px'}}>
                        <ListGroup className={'d-none d-md-block text-primary'} variant="flush">
                            {Boolean(userId) &&
                                <>
                                    <Link href={'/u/'.concat(userId)}>
                                        <ListGroup.Item action variant="primary">
                                            <div className={'d-flex flex-column align-items-center d-xl-none'}>
                                                <Icon name='user circle' size={'big'}/>
                                                <div>Profile</div>
                                            </div>
                                            <div className={'d-none d-xl-block'}>
                                                <div className={'d-inline-flex align-items-center'}>
                                                    <Icon name='user circle' size={'big'}/>
                                                    <h4>Profile</h4>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                    </Link>
                                    <Link href={'/m/notifications'}>
                                        <ListGroup.Item action variant="primary">
                                            <div className={'d-none d-xl-block'}>
                                                <div className={'d-flex flex-row  align-items-center'}>
                                                    <Icon name='bell' size={'big'}/>
                                                    <h4>Notifications</h4>
                                                </div>
                                            </div>
                                            <div className={'d-flex flex-column  align-items-center d-xl-none'}>
                                                <Icon name='bell' size={'big'}/>
                                                <div>Notifications</div>
                                            </div>
                                            {(notificationCount > 0) && <div>
                                                <Badge variant="warning">{notificationCount}</Badge>
                                            </div>}
                                        </ListGroup.Item>
                                    </Link>
                                    <Link href={'/m/bookmarks'}>
                                        <ListGroup.Item action variant="primary">
                                            <div className={'d-flex flex-column align-items-center d-xl-none'}>
                                                <Icon name='bookmark' size={'big'}/>
                                                <div>Bookmarks</div>
                                            </div>
                                            <div className={'d-none d-xl-block'}>
                                                <div className={'d-inline-flex align-items-center'}>
                                                    <Icon name='bookmark' size={'big'}/>
                                                    <h4>Bookmarks</h4>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                    </Link>
                                </>
                            }
                            <Link href={'/m/trends'}>
                                <ListGroup.Item action variant="primary">
                                    <div className={'d-flex flex-column align-items-center d-xl-none'}>
                                        <Icon name='search' size={'big'}/>
                                        <div>Search</div>
                                    </div>
                                    <div className={'d-none d-xl-block'}>
                                        <div className={'d-inline-flex align-items-center'}>
                                            <Icon name='search' size={'big'}/>
                                            <h4>Search</h4>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            </Link>
                            <Link href={'/m/communities'}>
                                <ListGroup.Item action variant="primary">
                                    <div className={'d-flex flex-column align-items-center d-xl-none'}>
                                        <Icon name='group' size={'big'}/>
                                        <div>Communities</div>
                                    </div>
                                    <div className={'d-none d-xl-block'}>
                                        <div className={'d-inline-flex align-items-center'}>
                                            <Icon name='group' size={'big'}/>
                                            <h4>Communities</h4>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            </Link>
                            <Link href={'/m/leaderboards'}>
                                <ListGroup.Item action variant="primary">
                                    <div className={'d-flex flex-column align-items-center d-xl-none'}>
                                        <Icon name='chart line' size={'big'}/>
                                        <div>Leaderboards</div>
                                    </div>
                                    <div className={'d-none d-xl-block'}>
                                        <div className={'d-inline-flex align-items-center'}>
                                            <Icon name='chart line' size={'big'}/>
                                            <h4>Leaderboards</h4>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            </Link>
                            <Link href={'/m/about'}>
                                <ListGroup.Item action variant="primary">
                                    <div className={'d-flex flex-column align-items-center d-xl-none'}>
                                        <Icon name='info circle' size={'big'}/>
                                        <div>About</div>
                                    </div>
                                    <div className={'d-none d-xl-block'}>
                                        <div className={'d-inline-flex align-items-center'}>
                                            <Icon name='info circle' size={'big'}/>
                                            <h4>About</h4>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            </Link>
                            <Link href={'/m/contact'}>
                                <ListGroup.Item action variant="primary">
                                    <div className={'d-flex flex-column align-items-center d-xl-none'}>
                                        <Icon name='envelope' size={'big'}/>
                                        <div>Contact Us</div>
                                    </div>
                                    <div className={'d-none d-xl-block'}>
                                        <div className={'d-inline-flex align-items-center'}>
                                            <Icon name='envelope' size={'big'}/>
                                            <h4>Contact Us</h4>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            </Link>
                            <Link href={'/m/user-agreement'}>
                                <ListGroup.Item action variant="primary">
                                    <div className={'d-flex flex-column align-items-center d-xl-none'}>
                                        <Icon name='handshake' size={'big'}/>
                                        <div>User Agreement</div>
                                    </div>
                                    <div className={'d-none d-xl-block'}>
                                        <div className={'d-inline-flex align-items-center'}>
                                            <Icon name='handshake' size={'big'}/>
                                            <h4>User Agreement</h4>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            </Link>
                            {Boolean(userId) ?
                                <ListGroup.Item action variant="primary" onClick={async () => {
                                    await signOut(auth)
                                }}>
                                    <div className={'d-flex flex-column align-items-center d-xl-none'}>
                                        <Icon name='power off' size={'big'}/>
                                        <div>Logout</div>
                                    </div>
                                    <div className={'d-none d-xl-block'}>
                                        <div className={'d-inline-flex align-items-center'}>
                                            <Icon name='power off' size={'big'}/>
                                            <h4>Logout</h4>
                                        </div>
                                    </div>
                                </ListGroup.Item> :
                                <ListGroup.Item action variant="primary" onClick={() => {
                                    dispatch(openModal('UnauthModal'))
                                }}>
                                    <div className={'d-flex flex-column align-items-center d-xl-none'}>
                                        <Icon name='power' size={'big'}/>
                                        <div>Login</div>
                                    </div>
                                    <div className={'d-none d-xl-block'}>
                                        <div className={'d-inline-flex align-items-center'}>
                                            <Icon name='power' size={'big'}/>
                                            <h4>Login</h4>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            }
                        </ListGroup>
                        <Button variant="success" className={'rounded w-100 p-3 text-capitalize'}
                                onClick={() => router.push('/m/compose')}>
                            Create Post
                        </Button>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={10} lg={7} xl={6} className={'min-vh-100'}>
                    <div className={'pb-5'}>
                        {mainContent}
                    </div>
                    <Footer notificationCount={notificationCount}/>
                </Col>
                <Col xs={0} sm={0} md={0} lg={4} xl={4} className={'bg-light-primary d-none d-lg-block'}>
                    {sidebarContent ? sidebarContent : renderTrends()}
                </Col>
            </Row>
        </Container>
    )
}

export default Layout