import React, {useState} from 'react';
import Navbar from "react-bootstrap/Navbar";
import {Col, Row} from "react-bootstrap";

import 'semantic-ui-css/components/icon.min.css'
import {useRouter} from "next/router";

function SearchNavBar({initialSearchTerm}) {
    const router = useRouter();
    const [searchTerm, setSearchTerm] = useState(initialSearchTerm)

    return (
        <Row noGutters className={'sticky-top'}>
            <Col xs={0} sm={0} md={2} lg={1} xl={2} style={{backgroundColor: '#008B4B'}}/>
            <Col xs={12} sm={12} md={10} lg={7} xl={6}>
                <Navbar style={{backgroundColor: '#008B4B'}} variant="dark" sticky={'top'} expand="md"
                        className={'p-0 py-1'}>
                    <div className="input-group md-form form-sm form-1 pl-0 justify-content-between pr-2">
                        <Navbar.Brand
                            className={"d-inline-flex align-items-center pl-2"}
                        >
                            <img
                                alt=""
                                src="/icons/icon-72x72.png"
                                width="34"
                                height="34"
                                className="border"
                                onClick={() => router.push('/')}
                            />{' '}
                        </Navbar.Brand>
                        <input className="form-control" type="text" placeholder="Search NaijaWorld ..."
                               aria-label="Search"
                               value={searchTerm}
                               onChange={(event) => setSearchTerm(event.target.value)}
                               onKeyPress={(event) => {
                                   if (event.key === 'Enter') {
                                       router.push('/trend/'.concat(encodeURIComponent(event.target.value)))
                                   }
                               }}
                        />
                    </div>
                </Navbar>
            </Col>
            <Col xs={0} sm={0} md={3} lg={4} xl={4} style={{backgroundColor: '#008B4B'}}/>
        </Row>
    );
}


export default SearchNavBar;