import React from 'react';
import {Icon} from "semantic-ui-react";
import Navbar from "react-bootstrap/Navbar";
import Link from "next/link";
import Nav from "react-bootstrap/Nav";
import {useDispatch} from "react-redux";
import {Badge, Col, ListGroup, Row} from "react-bootstrap";

import 'semantic-ui-css/components/icon.min.css'
import {openModal} from "../modals/modalActions";
import {auth} from "../../config/firebase";
import {signOut} from "firebase/auth";

function NavBar({userId, notificationCount}) {

    const dispatch = useDispatch()

    return (
        <Row noGutters className={'sticky-top'}>
            <Col xs={0} sm={0} md={2} lg={1} xl={2} style={{backgroundColor: '#008B4B'}}/>
            <Col xs={12} sm={12} md={10} lg={7} xl={6}>
                <Navbar style={{backgroundColor: '#008B4B'}} variant="dark" sticky={'top'} expand="md"
                        className={'d-flex align-items-start p-0 py-1'}>
                    <div id='test-id' className={'d-flex justify-content-between flex-grow-1 px-2 align-item-center'}>
                        <Link href="/" scroll={false}>
                            <div className={'d-flex align-item-center'}>
                                <Navbar.Brand
                                    className={"d-inline-flex align-items-center"}
                                >
                                    <img
                                        alt=""
                                        src="/icons/icon-72x72.png"
                                        width="34"
                                        height="34"
                                        className="border"
                                        onClick={() => window.scrollTo(0, 0)}
                                    />{' '}
                                </Navbar.Brand>
                                <Navbar.Text
                                    alt="We're building the best Nigerian forum and would like you to be a part"
                                    className={"text-white"}>
                                    <h5 className={'m-0'}>
                                        NaijaWorld
                                    </h5>
                                    <div style={{fontSize: 11, fontWeight: 390}}>
                                        Building Nigeria's Best Forum
                                    </div>
                                </Navbar.Text>
                            </div>
                        </Link>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    </div>
                    <div className={'d-md-none w-100 px-1 pb-1'}>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ml-auto text-primary">
                                <ListGroup variant="flush">
                                    <Link href={'/m/compose'}>
                                        <ListGroup.Item action variant="primary">
                                            <Icon name='pencil alternate' size={'big'}/>
                                            Create Post
                                        </ListGroup.Item>
                                    </Link>
                                    {Boolean(userId) &&
                                    <>
                                        <Link href={'/u/'.concat(userId)}>
                                            <ListGroup.Item action variant="primary">
                                                <Icon name='user circle' size={'big'}/>
                                                Profile
                                            </ListGroup.Item>
                                        </Link>
                                        <Link href={'/m/bookmarks'}>
                                            <ListGroup.Item action variant="primary">
                                                <Icon name='bookmark' size={'big'}/>
                                                Bookmarks
                                            </ListGroup.Item>
                                        </Link>
                                        <Link href={'/m/notifications'}>
                                            <ListGroup.Item action variant="primary"
                                                            className={'d-flex flex-row justify-content-between'}>
                                                <div>
                                                    <Icon name='bell' size={'big'}/>
                                                    Notifications
                                                </div>
                                                <h5>
                                                    <Badge variant="warning">{notificationCount}</Badge>
                                                </h5>
                                            </ListGroup.Item>
                                        </Link>
                                    </>
                                    }
                                    <Link href={'/m/communities'}>
                                        <ListGroup.Item action variant="primary">
                                            <Icon name='group' size={'big'}/>
                                            Communities
                                        </ListGroup.Item>
                                    </Link>
                                    <Link href={'/m/leaderboards'}>
                                        <ListGroup.Item action variant="primary">
                                            <Icon name='chart line' size={'big'}/>
                                            Leaderboards
                                        </ListGroup.Item>
                                    </Link>
                                    <Link href={'/m/about'}>
                                        <ListGroup.Item action variant="primary">
                                            <Icon name='info circle' size={'big'}/>
                                            About
                                        </ListGroup.Item>
                                    </Link>
                                    <Link href={'/m/contact'}>
                                        <ListGroup.Item action variant="primary">
                                            <Icon name='envelope' size={'big'}/>
                                            Contact Us
                                        </ListGroup.Item>
                                    </Link>
                                    {Boolean(userId) ?
                                        <ListGroup.Item action variant="primary"
                                                        onClick={async () => await signOut(auth)}>
                                            <Icon name='power off' size={'big'}/>
                                            Logout
                                        </ListGroup.Item> :
                                        <ListGroup.Item action variant="primary"
                                                        onClick={() => dispatch(openModal('UnauthModal'))}>
                                            <Icon name='power' size={'big'}/>
                                            Login
                                        </ListGroup.Item>
                                    }
                                    <Link href={'/m/user-agreement'}>
                                        <ListGroup.Item action variant="primary">
                                            <Icon name='handshake' size={'big'}/>
                                            User Agreement
                                        </ListGroup.Item>
                                    </Link>
                                </ListGroup>
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                </Navbar>
            </Col>
            <Col xs={0} sm={0} md={3} lg={4} xl={4} style={{backgroundColor: '#008B4B'}}/>
        </Row>
    );
}

export default NavBar;