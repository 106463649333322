import {Badge, Button} from "react-bootstrap";
import {Icon} from "semantic-ui-react";
import React from "react";
import {getUsername} from "../utilities/utils";
import {openModal} from "../features/modals/modalActions";
import {useRouter} from "next/router";
import {useDispatch} from "react-redux";

function Footer({notificationCount}) {
    const router = useRouter();
    const dispatch = useDispatch()
    return (
        <div
            className={"py-1 fixed-bottom d-md-none bg-primary text-center text-primary d-flex flex-row justify-content-around"}>
            <Button className={"border-0"} onClick={() => router.push('/')}>
                <Icon name="home" size={"big"}/>
            </Button>
            <Button className={"border-0"} onClick={() => router.push('/m/trends')}>
                <Icon name="search" size={"big"}/>
            </Button>
            <Button className={"border-0"} onClick={() => router.push('/m/communities')}>
                <Icon name="group" size={"big"}/>
            </Button>
            <Button className={"border-0"} onClick={() => router.push('/m/leaderboards')}>
                <Icon name="chart line" size={"big"}/>
            </Button>
            <Button className={"border-0"} onClick={() => {
                if (getUsername()) {
                    router.push('/m/notifications')
                } else {
                    dispatch(openModal('UnauthModal'))
                }
            }}>
                <Icon name="bell outline" size={"big"} className={'m-0'}/>
                {(notificationCount > 0) &&
                <sup>
                    <Badge variant="warning" pill>
                        {notificationCount}
                    </Badge>
                </sup>
                }
            </Button>
        </div>
    );
}

export default Footer